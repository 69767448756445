@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply font-body;
  }
  h1 {
    @apply font-title;
  }
}

@layer components {
  .main-bg-gradient {
    @apply bg-gradient-to-r from-cyan-400 to-emerald-300;
  }

  .light-bg-gradient {
    @apply bg-gradient-to-r from-cyan-300 to-emerald-300;
  }

  .active {
    @apply text-gradient;
  }

  .bg-primary {
    @apply bg-white text-black flex flex-col justify-center items-center;
  }

  .bg-secondary {
    @apply main-bg-gradient text-white flex flex-col justify-center items-center;
  }

  .btn-primary {
    @apply flex-1 px-5 py-2 rounded-full bg-black text-white md:text-lg font-title font-bold transition duration-500 ease-in-out hover:bg-opacity-0;
  }

  .btn-primary-helper {
    @apply inline-flex rounded-full main-bg-gradient;
  }

  .btn-nav {
    @apply flex-1 px-5 py-2 rounded-full font-title font-bold uppercase bg-white text-black transition duration-500 ease-in-out hover:bg-opacity-0 hover:text-white;
  }

  .btn-secondary-helper {
    @apply inline-flex rounded-full bg-black transition duration-500 ease-in-out hover:bg-white;
  }

  .btn-secondary {
    @apply flex-1 px-5 py-2 rounded-full md:text-lg font-title font-bold text-white text-gradient transition duration-500 ease-in-out hover:text-opacity-0;
  }

  .text-gradient {
    @apply bg-clip-text text-transparent light-bg-gradient;
  }

  .hover-gradient {
    @apply transition duration-500 ease-in-out bg-clip-text hover:text-transparent light-bg-gradient;
  }

  /* Golden Cherry Theme */

  .main-bg-gradient {
    @apply bg-gradient-to-r from-red-500 to-orange-400;
  }

  .light-bg-gradient {
    @apply bg-gradient-to-r from-red-500 to-yellow-400;
  }

  /* Cyan Theme */

  /* .main-bg-gradient {
    @apply bg-gradient-to-r from-cyan-400 to-emerald-300;
  }

  .light-bg-gradient {
    @apply bg-gradient-to-r from-cyan-300 to-emerald-300;
  } */

  /* Magenta Theme */

  /* .main-bg-gradient {
    @apply bg-gradient-to-r from-red-500 to-pink-400;
  }

  .light-bg-gradient {
    @apply bg-gradient-to-r from-red-500 to-pink-400;
  } */
}
